<template>
  <div class="h-badge bg-white">
    <slot/>
  </div>
</template>

<script>
export default {
  name: 'HBadge',
};
</script>

<style scoped>
  .h-badge {
    padding: 0 1rem;
    border: 1px solid #5eba00;
    border-radius: 5px;
    color: #5eba00;
    text-transform: capitalize;
    font-weight: 600;
  }
</style>
