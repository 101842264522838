<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <div class="card-title">Purchase Orders</div>
        <div class="card-options">
          <a
            v-if="!editFilter"
            href="#"
            class="text-decoration-none text-blue d-flex align-items-center"
            @click.prevent="editFilter = true">
            <i class="fe fe-arrow-left"/> View other dates
          </a>
        </div>
      </div>
      <div class="card-body">
        <po-report-filters
          :edit="editFilter"
          @edit="editFilter = true"
          @valid="setFilters"/>

        <div v-if="!editFilter" class="mt-6">
          <div class="row">
            <div
              v-for="stat in report.stats"
              :key="stat.status"
              class="col-lg-3">
              <div class="card" :class="POStatuses[stat.status].tagClass">
                <div class="card-body p-3 text-center">
                  <h1 class="m-0 mt-4 font-weight-bold">{{stat.count}}</h1>
                  <div class="text-muted mb-4">{{POStatuses[stat.status].value}}</div>
                </div>
              </div>
            </div>
            <div class="col-lg-3">
              <div class="card">
                <div class="card-body p-3 text-center">
                  <h1 class="m-0 mt-4 font-weight-bold">{{report.strike_rate}}</h1>
                  <div class="text-muted mb-4">Strike rate</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template v-if="!editFilter">
        <dimmer :active="submitting || listLoading">
          <div class="table-responsive">
            <table class="table table-hover table-outline table-vcenter card-table">
              <thead>
                <tr align="right">
                  <th colspan="12">
                    <b-dropdown
                      v-if="can([uiPermissions.PURCHASE_ORDERS_REPORT_EXPORT, uiPermissions.PURCHASE_ORDERS_BARCODES_EXPORT])"
                      text="Exports">
                      <b-dropdown-item-button
                        v-if="can(uiPermissions.PURCHASE_ORDERS_REPORT_EXPORT)"
                        :disabled="submitting"
                        @click="exportPo">
                        P.O. items
                      </b-dropdown-item-button>
                      <b-dropdown-item-button
                        v-if="can(uiPermissions.PURCHASE_ORDERS_BARCODES_EXPORT)"
                        :disabled="submitting"
                        @click="exportHcBarcodes">
                        HC barcodes
                      </b-dropdown-item-button>
                    </b-dropdown>
                  </th>
                </tr>
                <tr class="thead-light">
                  <th class="text-center w-1 text-nowrap">P.O. Number</th>
                  <th>Supplier</th>
                  <th>Delivery Date</th>
                  <th class="text-center w-1">Purchases</th>
                  <th class="text-center">Price Mismatch?</th>
                  <th class="text-center">GRN?</th>
                  <th>Status</th>
                  <th class="text-center w-1 text-nowrap">Strike Rate</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="item in items"
                  :key="item.id"
                  :class="{ 'table-danger': item.price_mismatch === 1 }">
                  <td class="text-center">
                    <router-link
                      v-if="can(uiPermissions.PURCHASE_ORDERS_VIEW)"
                      :to="{ path: `/purchase-order/reports/${item.id}`, query: $route.query }">
                      {{item.id}}
                    </router-link>
                    <span v-else>{{item.id}}</span>
                  </td>
                  <td>{{item.supplier.name}}</td>
                  <td>{{moment(item.delivery_date).format('ddd - D MMMM, YYYY')}}</td>
                  <td class="text-center">{{item.purchases_count}}</td>
                  <td class="text-center">{{item.price_mismatch === 1 ? 'Y' : 'N'}}</td>
                  <td class="text-center" :class="{ 'bg-yellow-light': item.goods_return_notes_count > 0 }">
                    {{item.goods_return_notes_count > 0 ? 'Y' : 'N'}}
                  </td>
                  <td>
                    <h-badge
                      v-if="POStatuses[item.status]"
                      :class="POStatuses[item.status].tagClass"
                      class="text-uppercase d-inline-block">
                      {{POStatuses[item.status].value}}
                    </h-badge>
                  </td>
                  <td class="text-center w-1 ">{{item.strike_rate}}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-if="!items.length" class="text-center">
            <h4>No Purchase Orders Found</h4>
            <div>
              <a
                href="#"
                class="text-decoration-none text-blue"
                @click.prevent="editFilter = true">
                View other dates
              </a>
            </div>
          </div>

          <pagination
            :currentPage="page"
            :totalPages="meta.totalPages"
            @goToPage="goToPage"></pagination>
        </dimmer>
      </template>
    </div>
  </div>
</template>

<script>

import list from '@/mixins/list';
import download from '@/mixins/download';
import PoReportFilters from '../filters/PoReportFilters';
import POStatuses from '@/assets/enums/POStatuses';
import HBadge from '@/components/ui/HBadge';
import Pagination from '@/components/Pagination.vue';
import {purchaseOrders} from '@/services';

export default {
  name: 'PoReports',
  components: {
    PoReportFilters,
    HBadge,
    Pagination,
  },
  mixins: [
    list,
    download,
  ],
  data() {
    return {
      POStatuses,
      editFilter: true,
      submitting: false,
      with: [
        'inboundChecklist',
        'supplier',
      ],
      with_count: [
        'purchases',
        'goodsReturnNotes',
      ],
      report: {
        strike_rate: '-',
        stats: [],
      },
    };
  },
  beforeCreate() {
    if (this.$route.query.from && this.$route.query.to) {
      this.editFilter = false;
    }
  },
  created() {
    this.getStats();
  },
  methods: {
    getStats() {
      if (this.$route.query.from && this.$route.query.to) {
        const params = {
          from: this.$route.query.from,
          to: this.$route.query.to,
        };

        purchaseOrders.getPoStats({params}).then(response => this.report = response);
      }
    },
    async exportPo() {
      const params = {
        from: this.$route.query.from,
        to: this.$route.query.to,
      };

      this.submitting = true;

      try {
        this.download(await purchaseOrders.exportByParameters(params));
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.submitting = false;
      }
    },
    async exportHcBarcodes() {
      const params = {
        from: this.$route.query.from,
        to: this.$route.query.to,
      };

      const config = {
        endpoint: 'barcodes',
      };

      this.submitting = true;

      try {
        this.download(await purchaseOrders.exportByParameters(params, config));
      }
      catch (ex) {
        console.error(ex);
      }
      finally {
        this.submitting = false;
      }
    },
    fetchData(page) {
      // request when filter is present only
      const payload = {
        with: this.with.join(','),
        with_count: this.with_count.join(','),
        limit: this.limit,
        page,
        query: this.query,
      };

      if (this.$route.query.from && this.$route.query.to) {
        payload.from = this.$route.query.from;
        payload.to = this.$route.query.to;
      }

      return purchaseOrders.getByParameters(payload);
    },
    setFilters(newValue) {
      // push the parameter in query
      const query = {
        from: newValue.from,
        to: newValue.to,
      };
      this.$router.replace({query});

      this.editFilter = false;
      // reset items
      this.items = [];
      this.refresh();
      this.getStats();
    },
  },
};
</script>
