<template>

  <dimmer :active="loading">
    <template v-if="edit">
      <form
        :class="{'was-validated': $v.filters.$dirty}"
        class="custom-form"
        @submit.prevent="validate">
        <div class="form-group row d-flex date-group">
          <label class="col-sm-5 col-form-label form-label text-right">Delivery date range</label>
          <div class="col-sm-4">
            <div class="d-flex align-items-center gutter">
              <input
                :value="$v.filters.from.$model"
                :class="{'is-invalid': $v.filters.from.$error}"
                :max="filters.to"
                type="date"
                class="form-control"
                @input="e => setValue({from: e.target.value})"/>
              <div>-</div>
              <input
                :value="$v.filters.to.$model"
                :class="{'is-invalid': $v.filters.to.$error}"
                :min="filters.from"
                type="date"
                class="form-control"
                @input="e => setValue({to: e.target.value})"/>
            </div>

            <template v-if="$v.filters.$dirty">
              <div v-if="!$v.filters.from.required || !$v.filters.to.required" class="invalid-feedback d-block">Delivery date range is required</div>
            </template>
          </div>
        </div>
        <div class="row justify-content-center mt-5">
          <div class="col-auto">
            <button class="btn btn-primary">Apply</button>
          </div>
        </div>
      </form>
    </template>
    <template v-else>
      <div>
        Delivery date range:
        <h4>{{moment(filters.from).format('D MMM YYYY')}} - {{moment(filters.to).format('D MMM YYYY')}}</h4>
      </div>
    </template>
  </dimmer>

</template>

<script>
import {required} from 'vuelidate/lib/validators';

export default {
  name: 'PoReportFilters',
  props: {
    edit: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      filters: {
        from: '',
        to: '',
      },
      loading: false,
    };
  },
  async created() {
    if (this.$route.query?.from && this.$route.query?.to) {
      this.loading = true;
      this.filters.from = this.$route.query.from;
      this.filters.to = this.$route.query.to;

      this.validate();
      this.loading = false;
    }
  },
  methods: {
    validate() {
      this.$v.filters.$touch();

      if (!this.$v.filters.$invalid) {
        this.$emit('valid', this.filters);
      }
    },
    setValue(value) {
      Object.assign(this.filters, value);
    },
  },
  validations: {
    filters: {
      from: {
        required,
      },
      to: {
        required,
      },
    },
  },
};

</script>

<style scoped>
.custom-form >>> .form-control {
  min-height: 3rem;
}
.custom-form >>> .custom-autocomplete .vs__dropdown-toggle {
  min-height: 3rem;
}
.custom-form .form-group {
  align-items: center;
  margin-bottom: 1.5rem;
}
</style>
