export default {
  open: {
    tagClass: 'border-success text-success',
    value: 'Open',
  },
  closed: {
    tagClass: 'border-danger text-danger',
    value: 'Closed',
  },
  received: {
    tagClass: 'border-info text-info',
    value: 'Received',
  },
  in_progress: {
    tagClass: 'border-warning text-warning',
    value: 'In Progress',
  },
};
